@import "../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.about-us {
  background: linear-gradient(
    to bottom,
    white 95%,
    rgba(31, 28, 27, 0.16) 100%
  );
  padding-bottom: vw(32px);

  @media (max-width: 480px) {
    padding-bottom: 14vw;
    background: linear-gradient(
      to bottom,
      white 92%,
      rgba(31, 28, 27, 0.16) 100%
    );
  }

  hr {
    border: none;
    border-top: 1px solid rgba(191, 191, 191, 1);
    margin: 3vw 0;
  }

  &__description {
    font-size: vw(65px);
    font-family: "Times Now";
    width: 80vw;
    text-align: left;
    margin-left: vw(40px);
    margin-bottom: vw(130px);
    margin-top: vw(33px);
    line-height: vw(66px);

    @media (max-width: 768px) {
      width: 92vw;
      padding-top: 2.604vw;
      line-height: 8vw;
      font-size: 6.4vw;
      line-height: 6.771vw;
      letter-spacing: 1px;
      margin-bottom: 11vw;
    }

    @media (max-width: 480px) {
      width: 94vw;
      line-height: 7.634vw;
      letter-spacing: 0.7px;
      font-size: 6.361vw;
    }
  }

  &__bullet {
    width: vw(12px);
    height: vw(12px);
    border-radius: 50%;
    background-color: $app-dark;

    @media (max-width: 480px) {
      font-size: 10vw;
    }
  }

  &__process {
    display: flex;
    justify-content: flex-end;
    width: 100vw;
    margin-bottom: vw(80px);

    &-description {
      font-size: vw(21px);
      font-family: "PP Neue Montreal Book";
      text-align: justify;
      line-height: vw(24px);
      max-width: 32%;
      word-wrap: break-word;
      margin-left: auto;
      margin-top: vw(5px);
      margin-bottom: vw(14px);
      padding-right: 10vw;

      @media (max-width: 768px) {
        padding: 7vw;
        font-size: 2.24vw;
        padding-right: 2.7vw;
        max-width: 46%;
        line-height: 2.7vw;
        margin-bottom: 8.5vw;
      }

      @media (max-width: 480px) {
        padding: 3vw;
        font-size: 5.589vw;
        line-height: 6.107vw;
        max-width: 100vw;
        margin-bottom: 8.5vw;
        color: rgba(124, 124, 124, 1);
      }
    }
  }

  &__links {
    font-family: "PP Neue Montreal Book";
    display: flex;
    justify-content: space-between;
    width: 45vw;
    margin-right: vw(30px);

    @media (max-width: 768px) {
      font-size: 4.8vw;
      flex-direction: row-reverse;
      text-align: left;
      margin-right: 0;
      justify-content: space-between;
    }

    @media (max-width: 480px) {
      font-size: 4.8vw;
      line-height: 6vw;
      flex-direction: column;
      padding-left: 3vw;
      padding-bottom: 5vw;
      justify-content: flex-start;
      gap: 8.142vw;
    }

    &-intro {
      display: flex;
      align-items: center;
      gap: vw(16px);

      @media (max-width: 768px) {
        letter-spacing: 0.2px;
        font-size: 2.3vw;
      }

      @media (max-width: 480px) {
        display: none;
      }
    }

    &-container {
      font-family: "PP Neue Montreal Book";
      font-size: vw(24px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: vw(24px);
      margin: 0 vw(40px) 0 vw(40px);

      @media (max-width: 768px) {
        padding-right: 0.651vw;
        font-size: 2.2vw;
        //margin-bottom: 2.7vw;
      }

      @media (max-width: 480px) {
        align-items: flex-start;
        flex-direction: column;
        white-space: nowrap;
        font-size: 4.8vw;
        margin-top: 18vw;
      }
    }
  }
}
