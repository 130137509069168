@import "../../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.featured-works {
  &__card {
    display: flex;
    flex-direction: column;
  }

  &__video {
    position: relative;

    &--large {
      width: vw(680px);
      height: vw(720px);

      @media (max-width: 480px) {
        width: 90vw;
        height: 75vw;
      }
    }

    &--medium {
      background-image: url("../../../resources/img/blur-background.webp");
      display: flex;
      width: vw(1130px);
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 auto;

      video {
        margin: vw(150px) auto;
        width: vw(784px);
        height: vw(480px);

        @media (max-width: 768px) {
          width: 65vw;
          margin: 14vw auto;
          height: 40vw;
        }
      }

      @media (max-width: 768px) {
        width: 95vw;
        height: 66vw;
      }

      @media (max-width: 480px) {
        width: 90vw;
        height: 70vw;
      }
    }

    &--small {
      background-color: $app-lilac;
      display: flex;
      width: vw(1130px);
      height: vw(780px);
      background-repeat: no-repeat;

      video {
        margin: vw(100px) auto;
        width: vw(784px);
        height: vw(480px);

        @media (max-width: 768px) {
          width: 75vw;
          margin: 10vw auto;
          height: 45vw;
        }
      }

      @media (max-width: 768px) {
        width: 95vw;
        height: 66vw;
      }

      @media (max-width: 480px) {
        width: 90vw;
      }
    }
  }

  .featured-works__image-wrapper {
    position: relative;
    overflow: hidden;
  }
  .parkwise-label {
    position: absolute;
    top: 45%;
    left: 20%;
  }

  &__image {
    &--full {
      width: 98%;
      height: 100%;
      position: relative;
      z-index: 1;
      object-fit: cover;

      @media (max-width: 480px) {
        width: 90vw;
        height: 75vw;
      }
    }

    &--overlay {
      position: absolute;
      top: 20%;
      height: 54%;
      left: 2%;
      z-index: 2;
    }

    &--standard {
      width: vw(550px);
      height: vw(420px);
      object-fit: cover;

      @media (max-width: 768px) {
        width: 46vw;
        height: 37vw;
      }

      @media (max-width: 480px) {
        width: 90vw;
        height: 70vw;
      }
    }

    &--tall {
      height: vw(720px);
      width: vw(670px);
      object-fit: cover;

      @media (max-width: 768px) {
        width: 46.354vw;
        height: 49.479vw;
      }

      @media (max-width: 480px) {
        width: 90vw;
        height: 90vw;
      }
    }

    &--reports {
      position: absolute;
      top: 20%;
      height: vw(400px);
      width: vw(600px);
      left: 5%;
      z-index: 2;
      object-fit: cover;

      @media (max-width: 480px) {
        width: 75vw;
        height: 45vw;
      }
    }
  }

  &__desc {
    font-size: vw(20px);
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: $my-white;

    @media (max-width: 768px) {
      letter-spacing: 0.5px;
    }

    @media (max-width: 480px) {
      width: 90vw;
    }

    &.wide {
      p:first-child {
        width: 90%;

        @media (max-width: 768px) {
          font-size: 2.1vw;
        }

        @media (max-width: 480px) {
          width: 90%;
          font-size: 4.58vw;
        }
      }
    }

    &.narrow {
      p:first-child {
        width: 60%;

        @media (max-width: 768px) {
          width: 70%;
          line-height: 3vw;
          font-size: 2.1vw;
        }

        @media (max-width: 480px) {
          width: 90%;
          font-size: 4.58vw;
          line-height: 5.4vw;
        }
      }
    }

    .featured-works__arrow {
      font-family: "PP Neue Montreal Book";
      font-size: vw(30px);
      margin: vw(14px);

      @media (max-width: 768px) {
        font-size: 3vw;
      }

      @media (max-width: 480px) {
        font-size: 6vw;
        margin-top: 3.3vw;
      }
    }
  }
}
