.burger {
  height: 100vh;
  background-color: rgb(0, 0, 0);
  font-family: "PP Neue Montreal Book";
  flex-direction: column;
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 10000;
  visibility: hidden;
  color: white;
  transition: opacity 0.7s ease;
  opacity: 0;

  @media (min-width: 481px) {
    display: none;
  }

  &__logo-text {
    font-size: 5.089vw;
    line-height: 6.107vw;
    display: flex;
    flex: 1;
  }

  &--visible {
    visibility: visible;
    opacity: 1;
    top: 0;
  }

  &__nav {
    display: flex;
    justify-content: space-between;

    &-list {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      font-size: 10.687vw;
      line-height: 15.013vw;
      padding: 0;

      li {
        list-style: none;
      }
    }

    &-link {
      color: white;
      mix-blend-mode: difference;
      align-items: center;
      text-decoration: none;
    }
  }

  &-close {
    right: 4vw;
    cursor: pointer;
    background: none;
    color: inherit;
    border: none;
    font: inherit;
    outline: inherit;
    width: 10%;
    z-index: 10000;

    &_cross {
      font-size: 5.107vw;
      font-weight: 700;
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    padding: 4vw;
    position: relative;
    justify-content: space-between;
    align-items: center;
  }

  &-main {
    display: flex;
    flex-direction: column;
    padding: 0 4vw 4vw 4vw;

    height: 90vh;
  }

  &-info {
    position: relative;
  }
}

.burger__copyright {
  bottom: 13%;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  font-family: "Times Now";
  text-align: center;
  font-size: 5.089vw;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.burger__cta-container {
  bottom: 25%;
  left: 0;
  right: 0;
  position: absolute;
  display: flex;
  margin: 0 auto;
  font-family: "PP Neue Montreal Book";
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 20vw;
  width: 90vw;
  height: 20.611vw;
  cursor: pointer;

  a {
    text-decoration: none;
    color: black;
    font-size: 6.107vw;
  }
}
