@import "../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.testimonial-links {
  margin: 0 0 vw(-8px) vw(5px);
  position: relative;
  display: inline-block;
  text-decoration: none;
  transition: color 0.4s ease;
  overflow: hidden;
  font-size: vw(26px);
  z-index: 1;
  font-family: "Times Now";
  color: $my-white;

  @media (max-width: 480px) {
    margin: 0 0 -2.5vw 2vw;
  }

  &:hover {
    color: $app-dark;
  }

  &::after {
    background-color: $my-white;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    z-index: -1;
    transition: height 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  &:hover::after {
    height: 100%;
  }

  span {
    display: inline-block;
    transform: rotate(-45deg);
    padding: 0 0 vw(5px) vw(5px);
    font-size: vw(14px);
    text-decoration: none;
    vertical-align: middle;
    font-family: "PP Neue Montreal Book";

    @media (max-width: 768px) {
      font-size: 1.302vw;
    }

    @media (max-width: 480px) {
      font-size: 4.4vw;
      padding: 0 0 1vw 1vw;
    }
  }

  @media (max-width: 768px) {
    font-size: 2.2vw;
    letter-spacing: 1px;
    padding-bottom: vw(2px);
  }

  @media (max-width: 480px) {
    width: fit-content;
    font-size: 6vw;
  }
}
