@import '../../style/variables.scss';

@function vw($px-vw, $base-vw: 1440px) {
    @return calc(($px-vw * 100vw) / $base-vw);
}

.nav-link__link {
    position: relative;
    display: inline-block;
    text-decoration: none;
    transition: color 0.6s ease;
    overflow: hidden;
    font-size: vw(22px);
    margin-bottom: vw(6px);
    z-index: 1;
    font-family: 'PP Neue Montreal Book';

    &.light {
        color: $my-white;

        &:hover {
            color: $app-dark;
        }

        &::after {
            background-color: $my-white;
        }

        &:hover::before {
            color: $app-dark;
        }
    }

    &.dark {
        color: $app-dark;

        &:hover {
            color: $my-white;
        }

        &::after {
            background-color: $app-dark;
        }

        &:hover::before {
            color: $my-white;
        }
    }

    &::before {
        padding: 1px 5px 0 1px;
        transition: transform 0.6s ease;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transition: transform 0.6s ease;
        transform: translateY(100%);

        @media (max-width: 376px) {
            bottom: 2px;
        }
    }

    &:hover::after {
        transform: translateY(0);
    }

    &:hover::before {
        transform-origin: center; 
        transform: rotate(-45deg);
    }

    @media (max-width: 768px) {
        font-size: 2.2vw;
        letter-spacing: 1px;
    }
    
    @media (max-width: 480px) {
        font-size: 4.8vw;
    }
}
