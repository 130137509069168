@import "../../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.footer-banner {
  margin: 0 auto;
  color: $my-black;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: vw(224px);
    @media (max-width: 769px) {
      margin-bottom: 22vw;
    }
  }

  &__heading {
    font-family: "PP Neue Montreal Book";
    font-size: vw(24px);
    margin: 0;
    font-weight: 600;

    @media (max-width: 768px) {
      font-weight: 400;
      font-size: 5.86vw;
      line-height: 1.2;
      max-width: 43vw;
    }
    @media (min-width: 769px) {
      font-size: vw(64px);
      line-height: 1.2;
      font-weight: 400;
      max-width: vw(461px);
    }
  }

  &__top {
    @media (min-width: 769px) {
      display: none;
    }
  }

  &__logo {
    margin-top: vw(130px);
    margin-bottom: vw(115px);
    width: vw(1005px);

    @media (max-width: 768px) {
      width: 66vw;
      margin-top: 10vw;
      margin-bottom: 10vw;
    }
    @media (min-width: 769px) {
      display: none;
    }
  }

  &__button {
    padding: vw(44px) vw(64px);
    border: 0;
    background-color: $app-dark;
    cursor: pointer;

    a {
      margin-right: 0;
      height: 0;
      width: 100%;
    }
    border-radius: vw(100px);

    @media (max-width: 768px) {
      display: none;
    }
  }

  .footer-banner-button__link {
    display: block;
    height: fit-content;
    padding: vw(27px) vw(64px) vw(24px);
    margin: 0;
    border: 0;
    background-color: $app-dark;
    border-radius: vw(100px);
    cursor: pointer;
    transition: transform 0.2s ease;

    @media (max-width: 768px) {
      padding: 2.3vw 5.8vw;
      transform: none;
    }
  }

  .footer-banner-button__link:hover {
    transform: rotate(5deg);

    @media (max-width: 768px) {
      transform: none;
    }
  }

  .footer-banner-button__logo {
    width: vw(340px);
    color: $my-white;
  }
}
