@import '../../style/variables.scss';

@function vw($px-vw, $base-vw: 1440px) {
    @return calc(($px-vw * 100vw) / $base-vw);
}

.read-more__link {
    position: relative;
    padding-left: vw(26px);
    display: inline-block;
    text-decoration: none;
    transition: color 0.4s ease;
    overflow: hidden;
    font-size: vw(22px);
    z-index: 1;
    font-family: 'PP Neue Montreal Medium';

    &.light {
        color: $my-white;

        &::before {
            content: '→';
            position: absolute;
            left: 0;
            display: inline-block;
            text-decoration: none;
            transform-origin: center;

            @media (max-width: 376px) {
                font-size: 4vw;
            }
        }

        &:hover {
            color: $app-dark;
        }

        &::after {
            background-color: $my-white;
        }

        &:hover::before {
            color: $app-dark;
        }
    }

    &.dark {
        color: $app-dark;

        &::before {
            content: '→';
            position: absolute;
            left: 0;
            display: inline-block;
            text-decoration: none;
            transform-origin: center;

            @media (max-width: 376px) {
                font-size: 4vw;
            }
        }

        &:hover {
            color: $my-white;
        }

        &::after {
            background-color: $app-dark;
        }

        &:hover::before {
            color: $my-white;
        }
    }

    &::before {
        transition: transform 0.8s cubic-bezier(0.25, 0.1, 0.25, 1), color 0.4s ease ;
    }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: vw(2px);
        z-index: -1;
        transition: height 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);

        @media (max-width: 768px) {
            height: vw(2px);
        }

        @media (max-width: 480px) {
            bottom: 0.254vw;
            height: 0.509vw;
        }
    }

    &:hover::after {
        height: 100%;
    }

    &:hover::before {
        transform-origin: center; 
        transform: rotate(-45deg);
    }

    @media (max-width: 768px) {
        font-size: 2.2vw;
        letter-spacing: 1px;
        padding-bottom: vw(2px);
    }
    
    @media (max-width: 480px) {
        width: fit-content;
        font-size: 4.8vw;
        padding-left: vw(80px);
    }
}
