@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.featured-works__heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 0.3s ease-in-out;
  margin-bottom: vw(45px);

  @media (max-width: 768px) {
    margin-bottom: 1.6vw;
  }

  @media (max-width: 480px) {
    margin-bottom: 0;
  }

  p {
    text-align: center;
    font-size: vw(157px);
    border-radius: 10px;
    margin: auto;
    font-family: "PP Neue Montreal Book";

    @media (max-width: 768px) {
      padding: 2.344vw 1.4vw 0 1.4vw;
      font-weight: 600;
      font-size: 10vw;
    }

    @media (max-width: 480px) {
      padding-top: 10.178vw;
      padding-bottom: 8.178vw;
      font-size: 10vw;
    }

    @media (max-width: 376px) {
      font-size: 10vw;
    }
  }
}

.blurry {
  filter: blur(5px);
}
