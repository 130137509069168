@import "../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.main-screen__mobile-container {
  font-family: "PP Neue Montreal Book";
}

.main-screen__mobile-help-talk {
  padding-bottom: 8vw;
}

.main-screen__video-mobile {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
}

.main-screen__mobile-container {
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: hidden;
  opacity: 0;
  animation: changeOpacity 1s ease-out 3.5s forwards;
}

.main-screen__mobile-container {
  height: auto;
}

.main-screen__mobile-info {
  padding: 4vw;
}

.main-screen__mobile-help-how {
  font-size: 12.214vw;
  line-height: 14.504vw;
  p {
    padding: 0;
    margin: 18vw 0 6vw 0;
  }
}
