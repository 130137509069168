@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.diverse-fields {
  display: flex;
  flex-direction: row;
  padding: vw(40px);
  padding-bottom: vw(20px);
  justify-content: space-between;
  font-family: "PP Neue Montreal Book";
  margin: vw(30px) 0;

  @media (max-width: 768px) {
    padding: 1.302vw;
    padding-bottom: 5.8vw;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    padding: 4vw;
    margin: 6vw 0;
  }

  &__title {
    font-size: vw(84px);
    margin-top: 0;

    @media (max-width: 768px) {
      margin-top: 5.99vw;
      font-size: 7.031vw;
    }

    @media (max-width: 480px) {
      margin-top: 3vw;
      font-size: 8.142vw;
    }
  }

  &__columns {
    display: flex;
    justify-content: space-between;
    line-height: vw(36px);
    width: 50%;
    font-size: vw(20px);

    @media (max-width: 768px) {
      margin-top: 6.5vw;
      font-size: 2.2vw;
      line-height: 4.1vw;
    }

    @media (max-width: 480px) {
      flex-direction: row;
      margin-top: 0.5vw;
      font-size: 4vw;
      line-height: 5.5vw;
      width: 100%;
    }
  }

  &__list {
    list-style: disc inside;
    padding: 0;
    margin: 0;
    flex: 1;
    margin-left: vw(10px);

    @media (max-width: 768px) {
      margin-left: 0.3vw;
    }

    @media (max-width: 480px) {
      margin-left: 1vw;
      font-size: 4.326vw;
      line-height: 5.191vw;

      li {
        padding: 1.527vw 0 1.527vw 0;
      }
    }
  }
}
