

@import '../../style/variables.scss';

@function vw($px-vw, $base-vw: 1440px) {
	@return calc(($px-vw * 100vw) / $base-vw);
}


.contact-us__link {
    position: relative;
    display: inline-block;
    color: $my-white;
    text-decoration: none;
	padding: 0 2px;
	transition: color 0.4s ease;
    font-size: vw(24px);

	&:after {
		content: '';
		position: absolute;
		left: 0;
		background-color: $my-white;
		bottom: 0;
		width: 100%;
		height: vw(1.8px);
		z-index: -1;
		transition: height 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);
	  }
	
	  &:hover {
		  color: $app-dark;
	  }
	
	  &:hover::after {
		  height: 100%;
	  }

		@media (max-width: 768px) {
			font-size: 2.2vw;
		}
		
		@media (max-width: 480px) {
			font-size: 5.3vw;
		}
}