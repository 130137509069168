@import "../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.featured-works {
  height: auto;
  background-color: $app-dark;
  color: $my-white;
  font-family: "PP Neue Montreal Book";

  @media (max-width: 480px) {
    height: auto;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    padding: vw(40px);
    gap: vw(140px);
    margin-bottom: vw(34px);

    &--center {
      justify-content: center;
    }

    @media (max-width: 768px) {
      gap: 1vw;
    }

    @media (max-width: 480px) {
      gap: 3vw;
      flex-direction: column;
      align-items: center;
    }
  }

  &__about {
    font-family: "PP Neue Montreal Book";
    font-size: vw(24px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 vw(25px);

    @media (max-width: 768px) {
      height: 3vw;
      font-size: 2.2vw;
      padding: 0 1vw 5vw 1vw;
    }

    @media (max-width: 480px) {
      flex-direction: column;
      height: auto;
    }

    &-bullet {
      width: vw(12px);
      height: vw(12px);
      border-radius: 50%;
      background-color: $my-white;
    }

    p {
      display: flex;
      align-items: center;
      gap: vw(16px);

      @media (max-width: 480px) {
        display: none;
      }
    }

    &-links {
      display: flex;
      justify-content: space-between;
      width: 47vw;
      margin: 1vw;

      @media (max-width: 768px) {
        width: 49vw;
      }

      @media (max-width: 480px) {
        width: 90vw;
        height: 20vw;
        padding-bottom: 2vw;
        flex-direction: column;
      }
    }
  }
}
