@import "../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.testimonials__header {
  display: flex;
  justify-content: space-between;
  padding: 0 vw(40px);

  @media (max-width: 768px) {
    padding: 0 1vw 2vw 1vw;
  }

  @media (max-width: 480px) {
    padding: 0 3vw 5vw 3vw;
  }

  p {
    font-weight: 500;
    font-size: vw(84px);
    font-family: "PP Neue Montreal Book";
    margin: 0 0 vw(10px) 0;

    @media (max-width: 768px) {
      font-size: 7vw;
    }

    @media (max-width: 480px) {
      font-size: 8.142vw;
    }
  }

  button {
    font-family: "PP Neue Montreal Book";
    font-size: vw(30px);
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    padding: vw(60px) 0 0 vw(22px);

    @media (max-width: 768px) {
      font-size: 4vw;
    }

    @media (max-width: 480px) {
      font-size: 5vw;
      padding: 3vw 0 0 5vw;
    }
  }
}

.testimonials__carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.testimonials__grid {
  height: 100%;
  width: calc(100vw + vw(80px));
  background-color: transparent;
  display: grid;
  grid-auto-flow: column;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  scrollbar-width: none;
  overflow-y: hidden;
  box-sizing: border-box;
  margin-left: vw(-20px);
}

.testimonials__slide {
  width: calc(73vw - vw(60px));
  height: vw(782px);
  border: 1px solid $border-color;
  border-right: none;
  display: flex;
  flex-direction: column;
  row-gap: 4vw;
  justify-content: space-between;
  align-items: center;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  padding: vw(40px);
  box-sizing: border-box;
  background: linear-gradient(
    to bottom,
    white 95%,
    rgba(31, 28, 27, 0.16) 100%
  );

  &:last-child {
    border-right: vw(1px) solid $border-color;
  }

  @media (max-width: 768px) {
    height: 70vw;
    padding: 20px;
  }

  @media (max-width: 480px) {
    width: calc(100vw - 20px);
    height: 90vw;
    padding: 15px;
  }
}

.testimonials__cover {
  width: vw(630px);
  height: vw(430px);
  object-fit: cover;
  margin: auto;

  @media (max-width: 768px) {
    height: 60%;
    width: 82%;
  }

  @media (max-width: 480px) {
    width: 75vw;
    height: 50vw;
  }
}

.testimonial {
  padding: vw(67px);
  padding-top: vw(150px);
  font-size: vw(36px);
  text-align: center;
  font-family: "Times Now";

  @media (max-width: 768px) {
    padding-top: 18.75vw;
    font-size: 2.4vw;
  }

  @media (max-width: 480px) {
    padding: 5vw;
    font-size: 4vw;
  }
}

.testimonials__client {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding: vw(40px) 0 0 vw(40px);

  @media (max-width: 768px) {
    padding: 2vw 1vw 2vw 0;
  }

  @media (max-width: 480px) {
    padding: 3vw 2vw 3vw 0;
  }

  &-info {
    flex-grow: 1;

    p {
      font-size: vw(24px);
      font-family: "PP Neue Montreal Book";
      margin: 0;

      @media (max-width: 768px) {
        font-size: 2.2vw;
      }

      @media (max-width: 480px) {
        font-size: 4.58vw;
        padding: 0 7vw;
      }
    }
  }

  &-title {
    color: $app-gray;
  }

  &-company {
    display: flex;
    align-items: baseline;
    text-align: right;
    margin-left: vw(20px);
    margin-right: vw(24px);

    @media (max-width: 480px) {
      padding: 0 1vw;
    }

    p {
      font-family: "PP Neue Montreal Book";
      font-size: vw(20px);
      margin: 0;
      padding-left: vw(18px);
      padding-bottom: vw(4px);

      @media (max-width: 768px) {
        margin-right: 2vw;
        font-size: 1.8vw;
      }

      @media (max-width: 480px) {
        font-size: 4vw;
      }
    }

    img {
      width: vw(14px);

      @media (max-width: 768px) {
        width: 1.3vw;
      }

      @media (max-width: 480px) {
        width: 3vw;
        margin-right: 2vw;
      }
    }

    span {
      width: vw(14px);
      margin-right: vw(10px);

      @media (max-width: 768px) {
        width: 10px;
        margin-right: 5px;
      }

      @media (max-width: 480px) {
        width: 2.036vw;
        margin-right: 0.763vw;
      }
    }
  }
}

.testimonials__nav button {
  color: $app-dark;
}

.testimonials__clients-links {
  background-color: $app-dark;
  color: $my-white;
  height: vw(210px);
  font-size: vw(26px);
  font-family: "Times Now";
  display: flex;
  justify-content: center;
  text-align: center;

  p {
    margin: vw(90px) auto;
    line-height: vw(22px);

    @media (max-width: 480px) {
      font-size: 6vw;
      line-height: 6vw;
      width: 90vw;
    }
  }

  @media (max-width: 768px) {
    font-size: 2.4vw;
    padding: 3.385vw;
  }

  @media (max-width: 480px) {
    height: auto;
    padding: 60px 20px;
    p {
      font-size: 6.361vw;

      margin: 0;
    }
  }
}
