@import "../../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.news-header {
  max-width: 100%;
  margin: 0 auto;
  color: $my-white;
  display: flex;
  padding: vw(20px) vw(5px) vw(30px) 0;
  justify-content: space-between;
  align-items: end;

  @media (max-width: 768px) {
    padding-bottom: 5.208vw;
    padding-right: 1vw;
  }

  @media (max-width: 480px) {
    padding: 8vw 4vw;
  }

  &__title {
    font-size: vw(84px);
    margin: 0;
    font-family: "PP Neue Montreal Book";

    @media (max-width: 768px) {
      font-size: 7vw;
    }

    @media (max-width: 480px) {
      font-size: 9.16vw;
    }
  }

  &__cta {
    @media (max-width: 480px) {
      display: none;
    }
  }
}
