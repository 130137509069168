$my-white: #ffffff;
$my-black: #000000;

$app-dark: #1F1C1B;
$app-lilac: #C7A0F4;
$border-color: #BFBFBF;
$app-gray: #7C7C7C;

$header-gradient-first: rgba(31, 28, 27, 0.6) 0%;
$header-gradient-second: rgba(31, 28, 27, 0) 92.93%
