@import '../../style/variables.scss';

@function vw($px-vw, $base-vw: 1440px) {
    @return calc(($px-vw * 100vw) / $base-vw);
}

body.no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
}

.splash-screen {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: $my-white;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    &--hidden {
        display: none;
    }
}

.splash-screen__text-slide {
    text-align: center;
    position: relative;
    font-family: 'PP Neue Montreal Medium';
    top: -24%;
}

.splash-screen__text-slide > div {
    display: inline-block;
    text-align: left;
    height: vw(40px);
    line-height: vw(40px);
    overflow: hidden;
    font-size: vw(40px);
}

.splash-screen__copyright {
    position: fixed;
    bottom: 3%;
    left: 3%;
    right: 0;
    opacity: 1;
    animation: changeOpacityToZero 1s linear 2.5s forwards;

    p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: vw(18px);
    }
}

.splash-screen__text {
    position: relative;
    animation: textAnimation ease 3s forwards;

    span {
        display: block;
        color: $my-black;
        padding-left: vw(10px);
    }
}

@keyframes textAnimation {
    0% {
        top: vw(-240px);
    }

    7% {
        top: vw(-240px);
    }

    14% {
        top: vw(-200px);
    }

    21% {
        top: vw(-200px);
    }

    28% {
        top: vw(-160px);
    }

    35% {
        top: vw(-160px);
    }

    42% {
        top: vw(-120px);
    }

    49% {
        top: vw(-120px);
    }

    56% {
        top: vw(-80px);
    }

    63% {
        top: vw(-80px);
    }

    70% {
        top: vw(-40px);
    }

    77% {
        top: vw(-40px);
    }

    84% {
        top: 0;
    }

    100% {
        top: 0;
    }
}

@supports (-webkit-touch-callout: none) {
    @media screen and (min-width: 480px) and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
        // iOS-specific styles
        .splash-screen__text-slide > div {
            height: 4vw; 
            line-height: 4vw; 
        }

        .splash-screen__text-slide > div {
            font-size: 3.5vw;
        }
    }
}

@media (max-width: 768px) {
    .splash-screen__text-slide > div {
        height: 3vw;
        line-height: 2.8vw;
    }

    .splash-screen__text-slide {
        top: -29%;
    }

    .splash-screen__text-slide > div {
        font-size: 3vw;
    }

    .splash-screen__copyright {
        margin: 4vw 5vw;

        p {
            font-size: 2.214vw;
        }
    }
}

@media (max-width: 480px) {
    .splash-screen__text-wrap, .splash-screen__text {
        display: none;
    }

    .splash-screen__text-slide {
        top: -4%;
        font-size: 7.5vw;
    }

    .splash-screen__text-slide > div {
        font-size: 7.5vw;
        line-height: 7.5vw;
        height: 8vw;
    }

    .splash-screen__copyright {
        margin: 4vw 5vw;
        text-align: center;

        p {
            font-size: 5.089vw;
            line-height: 5.852vw;
        }
    }
}

@keyframes changeOpacityToZero {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@media (max-width: 768px) and (min-width: 480px) {
    .splash-screen__text-slide > div {
        height: 3vw;
        line-height: 3vw;
        font-size: 3vw;
    }

    @supports (-webkit-touch-callout: none) {
        // Styles for iOS
        .splash-screen__text-slide > div {
            height: 3.2vw;
            line-height: 2.933vw;
        }
    }
    
    @supports not (-webkit-touch-callout: none) {
        // Styles for Android
        .splash-screen__text-slide > div {
            height: 3vw;
            line-height: 2.863vw;
        }
    }
}
