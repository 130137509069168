@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

@import "../../style/variables.scss";

.our-capabilities {
  font-family: "PP Neue Montreal Book";
  margin-bottom: vw(170px);
  margin-top: vw(54px);
  padding-top: vw(80px);
  color: $my-black;
  border-top: 1px solid #bfbfbf;

  @media (max-width: 768px) {
    margin-bottom: 16.927vw;
    margin-top: 10vw;
    padding-top: 5vw;
    border-top: 1px solid #bfbfbf;
  }

  @media (max-width: 480px) {
    margin-bottom: 25vw;
    margin-top: 0;
  }

  .our-capabilities__header {
    padding: 0 vw(40px) vw(12px) vw(40px);
    font-size: vw(20px);
    list-style-type: none;

    span {
      padding-right: vw(10px);
    }

    @media (max-width: 768px) {
      font-size: 2vw;
      padding: 0 4vw 2vw 4vw;
    }

    @media (max-width: 480px) {
      font-size: 4.58vw;
      padding: 0 5vw 3vw 5vw;
    }
  }
}
