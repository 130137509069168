@import "../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.app-footer {
  background-color: $app-lilac;
  padding: vw(40px) vw(40px) 0 vw(40px);

  @media (max-width: 768px) {
    padding: 2.6vw 2vw 4vw 2vw;
  }
}

.footer__content {
  color: $my-black;
  font-family: "PP Neue Montreal Book";
  background-color: $app-lilac;
  min-height: vw(270px);

  @media (max-width: 480px) {
    min-height: 21vw;
  }

  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
}
