@import "./variables.scss";

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  color: $my-black;
  background-color: $my-white;
  overflow-x: hidden;
}

html {
  width: 100%;
  overflow-x: hidden;
}

.scroll__container {
  position: relative;
  width: 100%;
  height: 100%;
}

main {
  position: fixed;
  scrollbar-width: thin;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.main {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.main.scrollable {
  overflow-y: scroll;
}

@font-face {
  font-family: "PP Neue Montreal Book";
  font-style: normal;
  font-weight: normal;
  src: local("PP Neue Montreal Book"),
    url("../fonts/pp-neue-montreal/ppneuemontreal-book.woff") format("woff");
}

@font-face {
  font-family: "PP Neue Montreal Medium";
  font-style: normal;
  font-weight: normal;
  src: local("PP Neue Montreal Medium"),
    url("../fonts/pp-neue-montreal/ppneuemontreal-medium.woff") format("woff");
}

@font-face {
  font-family: "Times Now";
  src: url("../fonts/times-now/TimesNow-SemiLight.eot");
  src: local("Times Now SemiLight"), local("TimesNow-SemiLight"),
    url("../fonts/times-now/TimesNow-SemiLight.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/times-now/TimesNow-SemiLight.woff2") format("woff2"),
    url("../fonts/times-now/TimesNow-SemiLight.woff") format("woff"),
    url("../fonts/times-now/TimesNow-SemiLight.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@keyframes changeOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
