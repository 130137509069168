@import "../../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.footer-right-block {
  width: 50%;
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__ratings {
    display: flex;
    justify-content: flex-start;
    margin-bottom: vw(105px);
    padding-left: vw(14px);
    align-items: center;
    font-family: "PP Neue Montreal Medium";

    @media (max-width: 768px) {
      margin-bottom: 9.7vw;
    }

    &-item {
      width: auto;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      margin-right: vw(35px);
      font-size: vw(14px);

      p {
        margin: 0;
      }

      @media (max-width: 768px) {
        width: 100%;
        font-size: 1.3vw;
      }
    }

    &-source {
      font-size: vw(48px);
      text-decoration: none;
      color: $my-black;

      @media (max-width: 768px) {
        font-size: 4.4vw;
      }
    }
  }

  &__bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "PP Neue Montreal Book";
    font-weight: 500;
    padding-left: vw(8px);
  }

  &__social-media {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: vw(8px);

    .footer-right-block__social-link {
      position: relative;
      text-decoration: none;
      font-size: vw(24px);
      color: $my-black;
      z-index: 1;
      transition: color 0.4s ease;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
        background-color: $app-dark;
        transition: height 0.4s ease;
      }

      &:hover {
        color: $app-lilac;
      }

      &:hover::after {
        height: 100%;
      }

      @media (max-width: 768px) {
        font-size: 2.2vw;
        letter-spacing: 0.5px;
      }
    }
  }
}
