@import "../../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.footer-mobile {
  margin: 0 auto;
  color: $my-black;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__heading {
    font-family: "PP Neue Montreal Book";
    font-size: 6.6vw;
    padding: 4vw;
    margin: 0 0 1vw;
    font-weight: 400;
  }

  &__contact {
    a {
      text-decoration: none;
      color: $my-black;
      margin: 0;
    }

    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 3.2vw;
    font-family: "PP Neue Montreal Book";
    font-weight: 400;
    margin: 0;
    font-size: 6.4vw;
    padding: 4vw;

    &-email,
    &-call {
      font-family: "PP Neue Montreal Medium";
      font-weight: 500;
      text-transform: uppercase;
    }

    .footer-mobile__social-link {
      text-decoration: none;
      width: 30vw;
      color: $my-black;
    }
  }

  &__ratings {
    display: flex;
    justify-content: space-between;
    margin-bottom: vw(84px);
    padding: 4vw;
    margin-top: 8vw;
    align-items: center;
    font-family: "PP Neue Montreal Medium";

    &-item {
      width: auto;
      display: flex;
      flex-direction: column;
      font-weight: 500;
      margin-right: vw(35px);

      p {
        margin: 0;
      }

      font-size: 3vw;
      line-height: 9vw;
    }

    &-source {
      font-size: 9.86vw;
      text-decoration: none;
      color: $my-black;
      line-height: 11.87vw;
    }
  }

  &__copyright {
    padding: 0 4vw;
    font-size: 5.089vw;
    font-family: "PP Neue Montreal Medium";

    p {
      margin: 0;
    }
  }

  &__policy {
    font-size: 5.089vw;
    font-weight: 500;
    margin-bottom: vw(40px);
    font-family: "Times Now";
    padding: 4vw;
    margin-top: 8vw;

    p {
      margin: 0;
    }

    a {
      color: $my-black;
      position: relative;
      text-decoration: none;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: vw(-2px);
        width: 100%;
        background-color: $my-black;
        transition: height 0.3s ease-out;
        height: 0.13vw;
      }

      &:hover::after {
        height: 0.26vw;
      }
    }
  }

  &__cta {
    padding: 6vw 4vw;
    margin-bottom: 6vw;

    button {
      border-radius: 20vw;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      outline: inherit;

      img {
        width: 100%;
        border-radius: 20vw;
      }
    }
  }
}
