@import "../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.news-section {
  background-color: $app-dark;
  width: 100vw;
  padding: vw(10px) vw(40px) vw(90px) vw(40px);
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding-left: 1vw;
    padding-top: 2.6vw;
    padding-bottom: 7.2vw;
  }

  &__news {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    color: $my-white;
    text-decoration: none;
    font-family: "Times Now";
    gap: vw(16px);

    @media (max-width: 768px) {
      gap: 3vw;
    }

    @media (max-width: 480px) {
      width: auto;
      padding: 3vw;
      flex-direction: column;
    }
  }

  &__cta {
    padding: 4vw;

    @media (min-width: 481px) {
      display: none;
    }
  }
}
