@import "../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.back-to-top {
  &__link {
    font-size: vw(24px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "PP Neue Montreal Book";
    text-decoration: none;
    color: $my-black;
    font-weight: 400;

    @media (max-width: 768px) {
      font-size: 2.2vw;
    }

    @media (max-width: 480px) {
      font-size: 3vw;
    }
  }

  &__arrow {
    margin-left: vw(8px);
    border-radius: 50%;
    border: vw(2px) solid black;
    width: vw(18px);
    height: vw(18px);
    font-size: vw(16px);
    font-weight: 900;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 1.5vw;
      width: 2vw;
      height: 2vw;
    }

    @media (max-width: 480px) {
      width: 2vw;
      height: 2vw;
    }
  }
}
