@import "../../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.news-post {
  width: 32%;
  min-width: vw(437px);
  margin-bottom: vw(20px);
  padding-bottom: vw(22px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    min-width: 46vw;
  }

  @media (max-width: 480px) {
    width: 90vw;
  }

  &__figure {
    margin: 0;
  }

  .news-post__image-wrapper {
    position: relative;
    overflow: hidden;
  }

  &__link {
    text-decoration: none;
    display: block;
  }

  &__title {
    margin-top: vw(16px);
    font-size: vw(26px);
    color: $my-white;
    box-sizing: border-box;

    @media (max-width: 768px) {
      margin-top: 2vw;
      font-size: 2.3vw;
      letter-spacing: 0.5px;
    }

    @media (max-width: 480px) {
      font-size: 5.089vw;
    }
  }

  &__image {
    width: vw(430px);
    height: vw(280px);
    object-fit: cover;
    display: block;

    @media (max-width: 768px) {
      width: 46vw;
      height: 31vw;
    }

    @media (max-width: 480px) {
      width: 90vw;
      height: 60vw;
    }
  }
}
