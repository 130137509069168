@import "../../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.accordion {
  margin: vw(10px) 0;
  color: $my-black;

  &__content {
    padding: 2% 0 7% 0;
    font-size: vw(20px);
    font-family: "PP Neue Montreal Book";
    text-align: left;
    max-width: 35%;
    word-wrap: break-word;
    margin-left: auto;
    padding-right: vw(160px);

    @media (max-width: 768px) {
      padding: 7vw;
      font-size: 1.823vw;
      max-width: 44vw;
    }

    @media (max-width: 480px) {
      font-size: 6vw;
      max-width: 100%;
      padding: 1vw;

      p {
        margin: 0;
      }
    }

    &-container {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
      color: $my-white;
      background-color: $app-dark;
    }
  }

  &__sign-indicator {
    @media (min-width: 480px) {
      display: none;
    }

    @media (max-width: 480px) {
      font-weight: 600;
    }
  }

  &__direction-indicator {
    position: absolute;
    height: vw(34px);
    right: vw(40px);
    width: vw(34px);
    background-color: $my-white;
    border-radius: 50%;
    border: 1px solid $app-dark;
    display: inline-block;
    z-index: 2;

    @media (max-width: 768px) {
      height: 3vw;
      width: 3vw;
    }

    @media (max-width: 480px) {
      height: auto;
      width: auto;
      right: 5vw;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: none;

      p {
        font-family: "PP Neue Montreal Medium";
        font-size: 13vw;
      }
    }
  }

  &__arrow {
    position: relative;
    font-size: vw(60px);
    font-family: "PP Neue Montreal Book";
    color: $my-white;
    margin: 0 0 0 vw(-53px);
    opacity: 1;
    transition: margin-left 0.3s ease-in-out;
    z-index: 4;
    transform: scale(1.4, 1);

    @media (max-width: 480px) {
      display: none;
    }
  }

  &__toggle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.28vw vw(40px) 0.28vw vw(20px);
    transition: color 0.6s ease, fill 0.15s ease;
    border: 0;
    background-color: transparent;
    cursor: pointer;

    @media (max-width: 768px) {
      padding: 0.5vw 1.3vw;
      letter-spacing: 2px;
    }

    @media (max-width: 480px) {
      padding: 4vw 5vw;
    }

    p {
      font-family: "Times Now";
      font-size: vw(84px);
      margin: 0;
      position: relative;
      z-index: 1;
      color: $app-dark;
      transition: color 0.3s ease-in-out;
      line-height: 1;

      &:hover {
        color: $my-white;
      }

      @media (max-width: 768px) {
        font-size: 6.77vw;
        letter-spacing: normal;
      }

      @media (max-width: 480px) {
        font-size: 8.142vw;
        line-height: 9.415vw;
        letter-spacing: normal;
      }
    }

    &:hover {
      color: $my-white;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    background-color: transparent;
    transition: color 1s ease-in-out;
    color: $my-black;

    &:hover,
    &.toggled {
      p {
        color: $my-white;
      }
      &::before {
        width: 100%;
      }

      .accordion__arrow {
        margin-left: 2.5vw;
        opacity: 1;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background-color: $app-dark;
      transition: width 0.4s ease-in;
      z-index: -1;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    //height: vw(90px);
    transition: height 0.3s ease-in-out;

    @media (max-width: 768px) {
      height: auto;
    }

    @media (max-width: 480px) {
      height: auto;
    }

    &.toggled {
      height: 100%;

      .accordion__toggle {
        color: $my-white;
        background-color: $app-dark;
      }

      .accordion__content-container {
        color: $my-white;
        background-color: $app-dark;
        max-height: vw(500px);
        transition: max-height 0.3s ease-in-out;

        @media (max-width: 768px) {
          padding: 7vw;
        }

        @media (max-width: 480px) {
          max-height: 150vw;
          padding: 8vw 4vw;
        }

        @media (max-width: 376px) {
          max-height: 65vw;
        }
      }
    }
  }
}
