@import "../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.app-header {
  position: fixed;
  height: vw(87px);
  width: 100%;
  max-width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(31, 28, 27, 0.6);
  background: linear-gradient(
    180deg,
    $header-gradient-first,
    $header-gradient-second
  );
  color: $my-white;
  padding-bottom: vw(20px);
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  overflow: hidden;
  z-index: 200;

  transition: transform 1s ease, opacity 0.3s ease;

  &.hidden {
    transform: translateY(-100%);
    opacity: 0;
  }

  &.visible {
    transform: translateY(0);
    opacity: 1;
  }
}

.header__left {
  font-family: "PP Neue Montreal Book";
  font-size: vw(24px);
  width: 20vw;
  display: flex;
  align-items: center;

  p {
    margin-left: vw(40px);
  }
}

.header__right,
.nav {
  font-size: vw(24px);
  width: 60vw;
  display: flex;
  align-items: center;
  font-family: "PP Neue Montreal Book";
  font-weight: 500;
  justify-content: space-between;
}

.nav__item {
  padding-right: 1vw;
  padding-top: 1vw;
}

.nav__list {
  display: flex;
  align-items: center;
  list-style: none;

  .nav__link {
    font-size: vw(24px);
    color: $my-white;
    margin: 0 vw(7px);
    transition: color 0.3s ease;
  }

  .nav__logo {
    background-color: $my-white;
    width: vw(12px);
    height: vw(12px);
    border-radius: 50%;
    margin: 0 vw(16px) vw(-2px) 0;
  }
}

.contact {
  margin-left: vw(40px);
  margin-right: vw(40px);
  margin-top: vw(8px);
  white-space: nowrap;
}

.menu-button {
  display: none;
  font-size: vw(24px);
  margin-left: vw(40px);
  margin-right: vw(70px);
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  color: $my-white;

  &__text {
    font-size: vw(24px);
    color: $my-white;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
  }

  &__bullet {
    font-size: vw(32px);
    font-family: "Courier New", Courier, monospace;
    margin-right: vw(10px);
    text-align: center;
    text-decoration: none;
  }
}

@media screen and (max-width: 768px) {
  .header__left {
    font-size: 2.16vw;
  }

  .header__right {
    width: 70vw;
  }

  .nav__list a {
    font-size: 2vw;
  }

  .nav__list {
    .nav__link {
      padding-left: 1vw;
      font-size: 2vw;
    }
  }

  .contact {
    padding-left: 6vw;
    margin-right: 7vw;
  }
}

@media screen and (max-width: 480px) {
  .app-header {
    height: 18.6vw;
    align-items: stretch;
    padding-bottom: 4vw;
  }

  .header__left {
    font-size: 4.1vw;
    width: 80vw;
  }

  .header__right {
    width: 30vw;
  }

  .nav__list,
  .contact {
    display: none;
  }

  .menu-button {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 10vw;

    &__text {
      font-size: 4.1vw;
    }

    &__bullet {
      font-size: 6.5vw;
      padding-top: 2px;
    }
  }
}
