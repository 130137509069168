@import "../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

// Video

.main-screen {
  display: flex;
  justify-content: center;
  height: 200%;
}

.main-screen__sticky {
  position: sticky;
  top: 0;
  bottom: -30%;
  width: 100%;
  height: 50%;
}

.main-screen__video {
  height: auto;
  clip-path: inset(100% 0 0 0);
  left: 0;
  object-fit: cover;
  opacity: 0;
  position: relative;
  transform: scale(0.35);
  transition: 0.3s linear;
  width: 100%;
  will-change: transform;
  z-index: 99;
  animation: revealFromBottom 1s ease-out 5.9s forwards;
}

@keyframes revealFromBottom {
  from {
    clip-path: inset(100% 0 0 0);
    opacity: 1;
  }
  to {
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}

.main-screen__video-container {
  display: grid;
  height: 100%;
  overflow: hidden;
  place-items: center;
  position: absolute;
  width: 100%;
  z-index: 98;
}

.main-screen__main-container {
  height: 30vw;
}

// Text

.main-screen__cascade-wrapper {
  height: 100vh;
  position: absolute;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.main-screen__cascade {
  width: 100vw;
  top: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 60vh;

  p {
    position: absolute;
    font-family: "PP Neue Montreal Medium";
    font-size: 2.8vw;
    top: 23%;
    left: 36%;
    transition: transform 5s cubic-bezier(0.25, 0.1, 0.25, 1);
    animation: changeFont 2.7s ease-in-out 3.5s forwards;

    span {
      padding: 0px;
    }

    @for $i from 1 through 11 {
      &:nth-child(#{$i}) {
        padding: 0 vw(5px);
        background-color: white;
        z-index: 1 - $i;
        transition: 1.5s cubic-bezier(0.25, 0.1, 0.25, 1);
      }
    }
  }
}

// Bottom row

.main-screen__help-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  z-index: 99;
  opacity: 0;
  animation: changeOpacity 1s ease-out 6.7s forwards;
}

.main-screen__help {
  box-sizing: border-box;
  font-family: "PP Neue Montreal Book";
  position: absolute;
  height: auto;
  width: 100%;
  bottom: vw(40px);
  display: flex;
  justify-content: space-between;
  padding: 0 vw(40px);
  font-size: vw(20px);
  align-items: flex-end;
  transition: transform 0ms ease-in-out;
  left: 0;
  transition: 0.3s linear;
  z-index: 999;
}

.main-screen__help-how {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: end;

  p {
    margin: 0 vw(40px) vw(2px) 0;
  }

  span {
    //padding: vw(30px);
    text-decoration: none;
    color: black;
    font-size: vw(42px);
    transform: scale(1, 1.25);
  }
}

.main-screen__help-talk {
  z-index: 1000;
}

// Animations

@keyframes slideUp {
  from {
    top: 100%;
    opacity: 1;
  }
  to {
    top: 0%;
    opacity: 1;
  }
}

@keyframes changeHeight {
  from {
    height: 70%;
  }
  to {
    height: 100%;
  }
}

@keyframes changeFont {
  0% {
    top: 23%;
    font-size: vw(40px);
    left: 36%;
    line-height: vw(140px);
  }

  30% {
    font-size: vw(160px);
    line-height: vw(140px);
    left: 4%;
    top: 6%;
  }

  85% {
    font-size: vw(160px);
    line-height: vw(140px);
    left: 4%;
    top: 6%;
  }

  100% {
    top: -3%;
    font-size: vw(170px);
    line-height: vw(135px);
    left: 2%;
  }
}

// Responsive

@media (min-width: 480px) {
  .main-screen__mobile-container {
    display: none;
  }
}

@media (max-width: 768px) {
  .main-screen__help {
    display: flex;
    flex-direction: column-reverse;
    gap: 5vw;
    align-items: baseline;
  }

  .main-screen__cascade {
    p {
      top: 21%;
    }
  }

  .main-screen__help-how {
    width: 65%;
    font-size: 2.214vw;
    text-align: justify;
    justify-content: start;

    span {
      display: none;
    }
  }

  @keyframes changeFont {
    0% {
      top: 21%;
      font-size: 2.8vw;
      left: 36%;
      line-height: vw(140px);
    }

    30% {
      font-size: vw(160px);
      line-height: vw(140px);
      left: 5%;
      top: 6%;
    }

    85% {
      font-size: vw(160px);
      line-height: vw(140px);
      left: 5%;
      top: 6%;
    }

    100% {
      top: 2%;
      font-size: vw(160px);
      line-height: vw(140px);
      left: 5%;
    }
  }
}

@media (max-width: 480px) {
  .main-screen__cascade {
    top: 0;
    p {
      top: 30%;
      left: 32%;
      font-size: 8vw;
      line-height: 8vw;
      margin: 5vh 0;

      span {
        display: none;
      }
    }
  }

  .main-screen {
    height: 90%;
  }

  .main-screen__cascade-wrapper {
    height: 100vh;
  }

  .main-screen__help {
    display: flex;
    flex-direction: column-reverse;
    top: 70%;
    bottom: 0;
    height: 20%;
    align-items: baseline;
    width: fit-content;
    padding: 16px;
  }

  .main-screen__help-how {
    width: 100%;
    align-self: center;
    font-size: 4.5vw;
    padding: 0;

    p {
      width: 100%;
    }
  }

  .main-screen__mobile-container {
    flex-direction: column;
    display: flex;
  }

  .main-screen__showreel {
    display: flex;
    z-index: 1000;
    justify-content: space-between;
  }

  .main-screen__video-container {
    display: none;
  }

  .main-screen__sticky {
    height: 90%;
    position: relative;
  }

  @keyframes changeFont {
    0% {
      top: 40%;
      left: 34%;
      font-size: 8vw;
      line-height: 8vw;
    }

    30% {
      font-size: 12vw;
      line-height: 12vw;
      left: 27%;
      top: 15%;
    }

    80% {
      font-size: 12vw;
      line-height: 12vw;
      left: 27%;
      top: 15%;
    }

    100% {
      top: 10%;
      font-size: 20vw;
      line-height: 20vw;
      left: 9%;
    }
  }
}

@media (max-height: 500px) {
  .main-screen__video-container {
    display: none;
  }

  .main-screen__cascade {
    top: 0;

    p {
      top: 30%;
      left: 32%;
      font-size: 8vw;
      line-height: 8vw;
    }
  }

  .main-screen {
    height: 160vh;
  }

  .main-screen__cascade-wrapper {
    height: 100vh;
  }

  .main-screen__help {
    display: flex;
    flex-direction: column-reverse;
    top: 80%;
    bottom: 100%;
    height: 100%;
    align-items: baseline;
  }

  .main-screen__help-talk {
    bottom: 100%;
    position: relative;
  }

  .main-screen__mobile-container {
    flex-direction: column;
    display: flex;
  }

  .main-screen__showreel {
    display: flex;
    z-index: 1000;
    justify-content: space-between;
  }

  .main-screen__sticky {
    height: 80%;
    position: relative;
  }

  @keyframes changeFont {
    0% {
      top: 40%;
      left: 32%;
      font-size: 8vw;
      line-height: 8vw;
    }

    30% {
      font-size: 12vw;
      line-height: 12vw;
      left: 24%;
      top: 15%;
    }

    85% {
      font-size: 12vw;
      line-height: 12vw;
      left: 24%;
      top: 15%;
    }

    100% {
      top: 10%;
      font-size: 20vw;
      line-height: 20vw;
      left: 9%;
    }
  }
}
