@import "../../../style/variables.scss";

@function vw($px-vw, $base-vw: 1440px) {
  @return calc(($px-vw * 100vw) / $base-vw);
}

.footer-left-block {
  width: 50%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__contact {
    margin-bottom: vw(86px);
    display: flex;
    flex-direction: column;
    gap: vw(8px);

    @media (max-width: 768px) {
      margin-bottom: 6vw;
    }

    a {
      position: relative;
      width: fit-content;
      font-size: vw(32px);
      font-weight: 500;
      font-family: "PP Neue Montreal Medium";
      margin: 0;
      line-height: vw(35px);
      text-decoration: none;
      color: $my-black;
      transition: color 0.4s ease;
      z-index: 1;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0;
        z-index: -1;
        background-color: $my-black;
        transition: height 0.4s ease;
      }

      &:hover {
        color: $app-lilac;
      }

      &:hover:after {
        height: 100%;
      }

      @media (max-width: 768px) {
        font-size: 2.6vw;
        line-height: 4vw;
      }

      @media (max-width: 480px) {
        font-size: 6vw;
        line-height: 9vw;
      }
    }
  }

  &__policy {
    font-size: vw(20px);
    margin-bottom: vw(40px);
    font-family: "Times Now";

    @media (max-width: 768px) {
      font-size: 2.2vw;
    }

    @media (max-width: 480px) {
      display: none;
    }

    p {
      margin: 0;
    }

    a {
      color: $my-black;
      position: relative;
      text-decoration: none;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: vw(-2px);
        width: 100%;
        height: vw(1px);
        background-color: $my-black;
        transition: height 0.3s ease-out;

        @media (max-width: 768px) {
          height: 0.13vw;
        }
      }

      &:hover::after {
        height: vw(2px);

        @media (max-width: 768px) {
          height: 0.26vw;
        }
      }
    }
  }
}
